import { ConfigService } from './config.service';
import { CommonService } from './core/services/common.service';

export function loadConfig(configService: ConfigService, commonService: CommonService) {
  return () => {
    let instance = window.location.host.split(".")[0]
    let formData = new FormData();
    formData.append("instance", instance);
    let object = commonService.createObject(formData)
    let token = commonService.createToken(object)
    let encryptedToken = commonService.encrypt(token)
    let data = new FormData()
    data.append("encrToken", encryptedToken);
    // Call the configService to load configuration
    return configService.loadConfig(data).toPromise().then(config => {
        configService.setConfig(config);
        // console.log("Configuration loaded:", config);
    }).catch(error => {
        // console.error("Error loading configuration:", error);
        throw error; // Prevent further bootstrap if config loading fails
    });
  }
    
}
