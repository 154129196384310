import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ConfigService } from './config.service';
import { environment } from '@env/environment';

export function MSALInstanceFactory(configService: ConfigService): IPublicClientApplication {
    const instance = window.location.host.split('.')[0];
    const instanceRslt = configService.getConfig();
    const msalConfig = JSON.parse(instanceRslt['DATA']['CONFIG']);
    // console.log("config :: ",msalConfig);
    const tenantId = msalConfig['tenantID'];
    const clientId = msalConfig['clientID'];
    const domain = environment.domain;
    // console.log("domain ",domain);

    return new PublicClientApplication({
        auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: `https://${instance}${domain}/auth/login`,
        postLogoutRedirectUri: `https://${instance}${domain}/auth/logout`,
        },
        cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
        allowRedirectInIframe: true,
        loggerOptions: {
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
        },
        },
    });
}
