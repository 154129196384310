import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { EmitService } from '@app/ts/services/emit.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private appService : AppService,private commonservice:CommonService,private http: HttpClient,private emitService: EmitService) {
  }
  routerPermission: any;
  canActivate(route: ActivatedRouteSnapshot,): Observable<boolean>|Promise<boolean>|boolean {
    let cookieExist = this.commonservice.getCookie(environment.loginCookie);
    // console.log(route['_routerState'].url);
    
    if (route['_routerState'].url == '/auth/login') {
      if (cookieExist == "") 
        return true;
      else
        this.router.navigate(['/dashboard']) 
    }
    else if (route['_routerState'].url == '/autologin' || route['_routerState'].url.includes('id_token')) {
      return true;
    }
    else{
      if (cookieExist == "") 
        this.router.navigate(['/auth/login'])
      else
        return true;
    }
   
    
  }

  private tokenExpired(token) {
    let cookieExpiredTime = JSON.parse(token)
    return new Date(cookieExpiredTime[environment.tokenExpireTime]).getTime() < new Date().getTime();
  }
  
}
