import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: any;

  constructor(private http: HttpClient) {}

  loadConfig(data): Observable<any> {
    return this.http.post(environment.AUTHURL+'getInstance',data); // Replace with your API endpoint
  }

  setConfig(config: any) {
    this.config = config;
  }

  getConfig() {
    return this.config;
  }
}
