import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from './app.service';
import { CommonService } from '@app/core/services/common.service';
import { UserService, LayoutService } from './core/services';
import { environment } from '@env/environment';
import { EmitService } from './ts/services/emit.service';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";
import { Location } from "@angular/common";
import { BrowserUtils, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from 'rxjs';

declare var $: any;
@Component({
  selector: 'app-root',
  template: `<router-outlet *ngIf="!isIframe"></router-outlet> <div class="cookie-consent hide-consent show-consent" *ngIf="showCookieConsent"><span class="cookie-text">{{'common_login_cookie_content' |i18n}} <a [href]="accountsUrl+'/privacy-policy'" target="_blank">{{'common_all_privacy_policy' | i18n}} </a></span><button class="consent-btn mt-4" (click)="hideCookieConsent()"> {{'footer_cookie_gotIt' | i18n}}</button></div>`,})
export class AppComponent{
  currentUrl;
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  action = '';
  showCookieConsent:boolean = true;
  // accountsUrl = environment.ACCOUNTS_URL;
  accountsUrl = window.location.origin
  companyCookie: any;
  instance: string;
  isIframe:boolean = false;
  loginDisplay: any;
  private readonly _destroying$ = new Subject<void>();

  constructor(private appService: AppService, private router: Router, private commonService: CommonService, private userService: UserService,
    private layoutService:LayoutService,private emitService: EmitService,private versionCheckService:VersionCheckService,private location: Location,
    private authService: MsalService, private msalBroadcastService: MsalBroadcastService){
    this.appService.setLocalStorageFunctions();  
    
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.instance = window.location.host.split(".")[0];
        let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.instanceCookie)));
        if( cookieExist&&this.instance !== JSON.parse(cookieExist).instance){
          this.router.navigate(['/auth/logout']);
        }
        this.currentUrl=e.url;
      }
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay){
     
      this.appService.msalLogged(true);
    }
    else{
      this.appService.msalLogged(false);
    }
  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnInit() {
    const currentPath = this.location.path();
    // Dont perform nav if in iframe or popup, other than for front-channel logout
    this.isIframe = BrowserUtils.isInIframe() && !window.opener && currentPath.indexOf("logout") < 0; // Remove this line to use Angular Universal
    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
      this.checkAndSetActiveAccount();
    })
    this.cookieConsent();
    this.versionCheckService.initVersionCheck('version.json');
  }
  cookieConsent(){
    if( this.commonService.getCookie(environment.cookieConsent) == 'false' ){
        this.showCookieConsent = false;
    }else{
      this.showCookieConsent = true;
      this.commonService.setCookie(environment.cookieConsent,"true",10000,environment.cookieDomain,'/');
    }
  }
  hideCookieConsent(delay =0){
    if(this.showCookieConsent){
      setTimeout(()=>{
      $(".cookie-consent").fadeOut(1500); 
      this.showCookieConsent = false ;
      this.commonService.setCookie(environment.cookieConsent,"false",10000,environment.cookieDomain,'/');   
      }, delay)
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

