import {Component, OnInit, ViewChild, Renderer2} from '@angular/core';
import {Router} from "@angular/router";
import { LayoutService } from '@app/core/services';
import { UserService } from '@app/core/services';
import { CommonService } from '@app/core/services/common.service';
import { I18nService } from '@app/shared/i18n/i18n.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { BsModalRef,ModalDirective } from 'ngx-bootstrap';
import { EmitService } from '@app/ts/services/emit.service';
declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('newBuild') public newBuild: ModalDirective;
  @ViewChild('cookieError') public cookieError: ModalDirective;
  store: any;
  light: any;
  dark: any;
  loadedDarkOptions;
  loadedDarkprofile;
  loadedLightOptions;
  loadedLightprofile;
  lanId: any;
  company;
  langChangeSubscription: Subscription;
  langaugeChanged: boolean = false;
  activitySelected: boolean = false;
  fullScreenSelected: boolean = true;
  notificationSelected: boolean = false;
  companyConfirmed: any;
  showCompanyAlert: boolean = false;
  isBexioUser: boolean = false;
  gotoMTC: boolean = false;
  buildAlert: String = "";
  showerrorAlert: boolean = false;
  logo: any;
  imgAlt = localStorage.getEncryptedItem('company');
  test_env: boolean;
  constructor(private router: Router, private layoutService: LayoutService, private i18nService: I18nService, 
    private commonService: CommonService, private userService: UserService,
    private emitService: EmitService,private renderer: Renderer2) {
      setTimeout(() => {
        if( environment.color != "" ){
          this.renderer.addClass(document.body, environment.color);
          this.test_env = true;
        }
        else{
          this.test_env = false;
        }
      });
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj =>
      setTimeout(() => {
        this.onLangChange(langObj);
      }, 500));
    let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.loginCookie)));
    let styleCookie,tempCookie;
    this.store = this.layoutService.store;

    let skins = this.store.skins;

    if (cookieExist) {
        if(JSON.parse(cookieExist)['style'])
        {
          styleCookie = JSON.parse(JSON.parse(cookieExist)['style']);
        }else{
          tempCookie = JSON.parse(cookieExist);
          skins[1]["smartSkin"] = "smart-style-5 darktheme";
          tempCookie['style'] = JSON.stringify(skins[1]);
          this.commonService.setCookie(environment.loginCookie,this.commonService.encrypt(JSON.stringify(tempCookie)),30000 * 60 * 30,environment.domain)
        }
    }
    let styleTheme = styleCookie ?  styleCookie.smartSkin.includes("darktheme") ? skins[1]:  skins[2] : skins[1]; 

    styleTheme ? this.layoutService.onSmartSkin(styleTheme) : '';
    let status;
    setTimeout(() => {
      styleTheme ? styleTheme.name == 'smart-style-2' ? $("#themeStyle").prop("checked", true) : $("#themeStyle").prop("checked", false) : '';
    });
    this.emitService.newDistEvent.subscribe(data => {
      if (data) {
        this.buildAlert = this.i18nService.getTranslation('build_alert');
        this.newBuild.show();
      }

    })
    this.emitService.refreshApiErrorEvent.subscribe(data => {
      // if(data)
      //   this.cookieError.show();
    })
    this.loadCompanyLogo();

  }
  onLangChange(langObj: any) {
    langObj.isHandled = true;
    this.loadedDarkOptions = false;
    this.loadedDarkprofile = false;
    this.loadedLightprofile = false;
    if (this.langaugeChanged) {
      setTimeout(() => {
        this.lanId = this.commonService.getCurrentLanguageCode();
      }, 1000);

    }

    this.langaugeChanged = true;
  }

  selectActivity(data) {
    // this.activitySelected = data;
    if (data.type == 'notification') {
      this.notificationSelected = data.value;
    } else
      this.activitySelected = data.value;
  }
  selectFullScreen(data) {
    this.fullScreenSelected = data;
  }

  ngOnInit() {
    this.showerrorAlerts();
    this.companyApprove();
    localStorage.getEncryptedItem('isBexioCompany') == 'true' ? this.isBexioUser = true : this.isBexioUser = false;
    this.store = this.layoutService.store;
    let skins = this.store.skins;
    for (let i = 0; i < skins.length; i++) {
      if (skins[i].name == 'smart-style-1')
        this.dark = skins[i];
      if (skins[i].name == 'smart-style-2')
        this.light = skins[i];
    }

    this.buildAlert = this.i18nService.getTranslation('build_alert')

  }


  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }

  routeToProfile() {
    this.router.navigate(['/profile']);
  }
  onSelectTheme(e) {
    if (e.currentTarget.checked == true) {
      this.commonService.setCookie(this.commonService.encrypt(environment.styleCookie), this.commonService.encrypt(JSON.stringify(this.light)), 10000, environment.cookieDomain, '/')
      this.layoutService.onSmartSkin(this.light)
      $('.menuIconsOption').siblings('div').first().removeClass(this.dark.name).addClass(this.light.name);
      $('.menuIcons').siblings('div').first().removeClass(this.dark.name).addClass(this.light.name);
    } else {
      this.commonService.setCookie(this.commonService.encrypt(environment.styleCookie), this.commonService.encrypt(JSON.stringify(this.dark)), 10000, environment.cookieDomain, '/')
      this.layoutService.onSmartSkin(this.dark)
      $('.menuIconsOption').siblings('div').first().removeClass(this.light.name).addClass(this.dark.name);
      $('.menuIcons').siblings('div').first().removeClass(this.light.name).addClass(this.dark.name);
    }
    this.loadedDarkprofile = false;
    // $('profile-button').remove();
    // this.loadDarkProfile();
    this.loadedDarkOptions = false;
    // $('custom-button').remove();
    // this.loadDarkOptions();
    this.loadCompanyLogo();
  }
  companyApprove() {
    this.companyConfirmed = localStorage.getEncryptedItem('isCompanyConfirmed');
    // this.companyConfirmed = false;
    if (this.companyConfirmed != 'true' && !localStorage.getEncryptedItem('companyAlert')) {
      this.showCompanyAlert = true;
      localStorage.setEncryptedItem("companyAlert", "true");
    } else {
      this.showCompanyAlert = false;
    }
  }

  clearCache() {
    localStorage.clear();
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      if (cookies[c].split(";")[0].split("=")[0] !== environment.cookieConsent) {
        let d = window.location.hostname.split(".");
        while (d.length > 0) {
          let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
          let p = location.pathname.split('/');
          document.cookie = cookieBase + '/';
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
          };
          d.shift();
        }
      }
    }
    location.reload()
  }
  showerrorAlerts() {
    this.emitService.refreshApiErroralert.subscribe(data => {
      if (data) {
        this.showerrorAlert = true
        // location.reload()

      }
    });

  }

  errorAlertOk() {
    this.cookieError.hide();
    localStorage.clear();
    // window.location.href = environment.LOGOUT_URL+encodeURIComponent(this.commonService.encrypt(window.location.href))
  }

  redirectToLogin() {
    this.router.navigate(['/auth/logout']);
    this.clearCache();
  }
  loadCompanyLogo() {
    if (localStorage.getItem('sm-skin') == 'smart-style-1') {
      if (localStorage.getEncryptedItem('logo')) {
        let logo = JSON.parse(localStorage.getEncryptedItem('logo')).whiteTheme != '' ? JSON.parse(localStorage.getEncryptedItem('logo')).whiteTheme : JSON.parse(localStorage.getEncryptedItem('logo')).normalLogo;
        this.logo = environment.NETGATE_URL + logo;
      }
    } else {
      if (localStorage.getEncryptedItem('logo')) {
        let logo = JSON.parse(localStorage.getEncryptedItem('logo')).normalLogo;
        this.logo = environment.NETGATE_URL + logo;
      }
    }
  }
}